<!-- eslint-disable vue/require-v-for-key -->
<template>
  <article>
    <div>
      <alert-dialog :options="alertProps" />
      <confirm-dialog :options="confirmProps" />
      <div :class="`md-reply ${commentSurvey ? 'reply-survey-type' : ''}`">
        <p
          v-show="contentType == 1 || contentType == 2 || contentType == 4"
          class="reply-title"
        >
          댓글
        </p>
        <div class="reply-list">
          <div
            v-if="commentSurvey"
          >
            <div
              v-for="e in commentSurvey.commentSurveyElements"
              :key="e.id"
              class="reply-survey-wrap"
            >
              <div class="reply-survey-question">
                {{ e.title }}
              </div>

              <div class="reply-survey-date">
                <span>
                  참여기간
                  <em style="margin-left:6px">
                    {{ commentSurvey.startDate | $dateFormatter('YYYY.MM.DD') }}
                    -
                    {{ commentSurvey.endDate | $dateFormatter('YYYY.MM.DD') }}

                  </em>
                </span>
              </div>
              <div class="s-guide-txt">
                ※  의견 선택 후 댓글을 작성해야 참여가 완료<em>됩니다.</em>
              </div>

              <div
                class="reply-survey-items"
              >
                <div
                  v-for="c in e.commentSurveyElementChoices"
                  :key="e.id+'-'+c.id"
                  :class="c.status"
                  @click.prevent="isCommentSurveyEnd || isAnswerSummitComplete ? '' : commentSurveyAnswerSelect(e, c)"
                >
                  <span class="title">
                    <em
                      class="num-round"
                      :style="`${'background:'+c.colorCode}`"
                    >{{ c.seq }}</em>
                    {{ c.description }}
                  </span>
                  <div
                    v-if="isCommentSurveyEnd || isAnswerSummitComplete"
                    class="rs-item-result"
                  >
                    <span class="percent-num">{{ totalCommentSurveyAnswerCount ? parseInt((c.answerCount/totalCommentSurveyAnswerCount) *100) : 0 }}</span>
                    <span class="percent">%</span>
                    <span class="total">({{ c.answerCount }}명)</span>
                  </div>
                  <div
                    class="bar-range"
                    :style="`width: ${(isCommentSurveyEnd || isAnswerSummitComplete ? (totalCommentSurveyAnswerCount ? parseInt((c.answerCount/totalCommentSurveyAnswerCount) *100) : 0) : 100)}%`"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!commentSurvey || (commentSurvey && !isCommentSurveyEnd)"
            class="reply-write"
          >
            <div class="textarea-wrap">
              <textarea
                v-model.trim="commentText"
                style="resize: none"
                placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                @click.stop="checkedLogin"
              />
            </div>
            <button
              class="btn-registration"
              @click.stop="register"
            >
              등록
            </button>
          </div>

          <template
            v-for="(item, index) in commentlist"
          >
            <div
              v-if="item.modifyComment"
              :key="item.commentId"
              class="replay-item reply-edit"
            >
              <p
                class="info"
                style="padding-left: 0px;"
              >
                <span
                  v-if="item.commentSurveyAnswer && commentSurvey"
                  :style="`margin-right: 5px;`"
                >
                  <em
                    v-for="(answer, idx) in stringToArray(item.commentSurveyAnswer)"
                    :key="idx"
                    class="num-round"
                    :style="`position: relative; display: inline-block; margin-right: 5px; background: ${getAnswerInfo(answer, 'color')}`"
                  >{{ getAnswerInfo(answer, 'seq') }}</em>
                </span>
                <span class="name">{{ item.userName }}</span>
                <span
                  v-if="!item.isWithDrawUser && !item.isAdmin"
                  class="class"
                >{{ item.hcpMajorName }}</span>
                <span class="date">{{ item.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                <span class="time">{{ item.modifyDate | $dateFormatter('HH:mm') }}</span>
              </p>

              <div class="re-reply-write edit-type">
                <div class="textarea-wrap">
                  <textarea
                    v-model.trim="item.commentText"
                    placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                  />
                </div>
                <div class="flex-r btn-edit">
                  <button
                    class="btn-s-registration"
                    @click.stop="updateComment(item)"
                  >
                    수정
                  </button>
                  <button
                    class="btn-cancel"
                    @click.stop="modifyComment(item)"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>

            <div
              v-else
              :key="item.commentId"
              class="replay-item my-write"
            >
              <p
                class="info"
                style="padding-left: 0px;"
              >
                <span
                  v-if="item.commentSurveyAnswer && commentSurvey"
                  :style="`margin-right: 5px;`"
                >
                  <em
                    v-for="(answer, idx) in stringToArray(item.commentSurveyAnswer)"
                    :key="idx"
                    class="num-round"
                    :style="`position: relative; display: inline-block; margin-right: 5px; background: ${getAnswerInfo(answer, 'color')}`"
                  >{{ getAnswerInfo(answer, 'seq') }}</em>
                </span>
                <span :class="item.isWithDrawUser ? 'name withdraw' : 'name'">{{ item.isWithDrawUser ? '존재하지 않는 회원입니다.' : item.userName }}</span>
                <span
                  v-if="!item.isWithDrawUser && !item.isAdmin"
                  class="class"
                >{{ item.hcpMajorName }}</span>
                <span class="date">{{ item.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                <span class="time">{{ item.modifyDate | $dateFormatter('HH:mm') }}</span>
              </p>

              <p
                v-if="item.isDelete"
                class="txt delete-type"
              >
                <i class="ico-delete" />삭제된 댓글입니다.
              </p>

              <p
                v-else
                class="txt"
              >
                <template v-for="(msg, mIdx) in splitMessages(item.commentText)">
                  {{ msg }} <br :key="`comment_${mIdx}`">
                </template>
              </p>

              <div class="btn-all">
                <button
                  v-if="!item.isDelete &&(!commentSurvey || (commentSurvey && !isCommentSurveyEnd))"
                  @click="showReply(item)"
                >
                  답글 쓰기
                </button>
                <button
                  v-if="item.isEdit && !item.isDelete"
                  @click="modifyComment(item)"
                >
                  수정
                </button>
                <button
                  v-if="item.isEdit && !item.isDelete"
                  @click="removeComment(item)"
                >
                  삭제
                </button>
              </div>
            </div>

            <div
              v-show="item.replyWrite"
              :key="index"
              class="replay-item re-reply edit-type"
            >
              <div class="re-reply-write">
                <div class="textarea-wrap">
                  <textarea
                    v-model.trim="item.replyComment"
                    style="resize: none"
                    placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                  />
                </div>
                <div class="flex-r btn-edit">
                  <button
                    class="btn-s-registration"
                    @click="registerReply(item)"
                  >
                    등록
                  </button>
                  <button
                    class="btn-cancel"
                    @click="cancelReply(item)"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>

            <div
              v-for="reply in item.childrenComments"
              :key="reply.commentId"
            >
              <div
                v-if="reply.modifyReply"
                class="replay-item re-reply"
              >
                <p
                  class="info"
                >
                  <span
                    class="name"
                    style="padding-left: 25px;"
                  >
                    <span
                      v-if="reply.commentSurveyAnswer && commentSurvey"
                    >
                      <em
                        v-for="(answer, idx) in stringToArray(reply.commentSurveyAnswer)"
                        :key="idx"
                        class="num-round"
                        :style="`left: 0px; position: relative; display: inline-block; margin-right: 5px; background: ${getAnswerInfo(answer, 'color')}`"
                      >{{ getAnswerInfo(answer, 'seq') }}</em>
                    </span>
                    {{ reply.userName }}</span>
                  <span class="class">{{ reply.hcpMajorName }}</span>
                  <span class="date">{{ reply.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                  <span class="time">{{ reply.modifyDate | $dateFormatter('HH:mm') }}</span>
                </p>

                <div class="re-reply-write">
                  <div class="textarea-wrap">
                    <textarea
                      v-model.trim="reply.commentText"
                      class="active"
                      placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                    >좋은 컨텐츠 감사합니다</textarea>
                  </div>
                  <div class="flex-r btn-edit">
                    <button
                      class="btn-s-registration"
                      @click="updateComment(reply)"
                    >
                      수정
                    </button>
                    <button
                      class="btn-cancel"
                      @click="modifyReply(reply)"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="replay-item re-reply"
              >
                <p
                  class="info"
                >
                  <span
                    :class="reply.isWithDrawUser ? 'name withdraw' : 'name'"
                    style="padding-left: 25px;"
                  >
                    <span
                      v-if="reply.commentSurveyAnswer && commentSurvey"
                    >
                      <em
                        v-for="(answer, idx) in stringToArray(reply.commentSurveyAnswer)"
                        :key="idx"
                        class="num-round"
                        :style="`left: 0px; position: relative; display: inline-block; margin-right: 5px; background: ${getAnswerInfo(answer, 'color')}`"
                      >{{ getAnswerInfo(answer, 'seq') }}</em>
                    </span>
                    {{ reply.isWithDrawUser ? '존재하지 않는 회원입니다.' : reply.userName }}</span>
                  <span
                    v-if="!reply.isWithDrawUser && !reply.isAdmin"
                    class="class"
                  >{{ reply.hcpMajorName }}</span>
                  <span class="date">{{ reply.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                  <span class="time">{{ reply.modifyDate | $dateFormatter('HH:mm') }}</span>
                </p>
                <p
                  v-if="reply.isDelete"
                  class="txt delete-type"
                >
                  <i class="ico-delete" />삭제된 답글입니다.
                </p>

                <p
                  v-else
                  class="txt"
                >
                  <template v-for="(msg, mIdx) in splitMessages(reply.commentText)">
                    {{ msg }} <br :key="`comment_${mIdx}`">
                  </template>
                </p>

                <div class="btn-all">
                  <button
                    v-if="reply.isEdit && !reply.isDelete"
                    @click="modifyReply(reply)"
                  >
                    수정
                  </button>
                  <button
                    v-if="reply.isEdit && !reply.isDelete"
                    @click.prevent="removeComment(reply)"
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { getUserLoginInfo, confirmPointMission } from '@/common/userInfo/service'
import { setStatistics } from '@/common/statistics/service'

export default {
  props: {
    contentType: {
      type: Number,
      required: true,
    },
    isHook: {
      type: Boolean,
      default: false,
    },
    memberOnly: {
      type: Boolean,
      default: true,
    },
    commentSurveyId: {
      type: Number,
      default: -1,
    }
  },

  data() {
    return {
      contentId: 0,
      commentlist: [],
      commentText: null,
      isProcessing: false,
      commentSurvey: null,
      isCommentSurveyEnd: true,
      isAnswerSummitComplete: false,
      userCommentSurveyAnswer: [],
      isAnswerPickComplete: false,
      totalCommentSurveyAnswerCount: 0,
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    })
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  mounted() {
  },

  methods: {
    splitMessages(message) {
      return message.split('\n')
    },

    fetchComment(id, contentType) {
      axios.get(`/fu/comment/${id}`, {
        params: {
          contentType,
          userHash: this.shaEnLicenseNum
        },
      })
        .then((rs) => {
          this.contentId = id
          this.contentType = contentType

          this.commentlist = rs.data

          // 설문 로드
          axios.get(`/fu/comment/survey/${this.commentSurveyId}`)
            .then((cs) => {
              if (cs) {
                this.commentSurvey = cs.data

                const kstDate = new Date(new Date().getTime() + (9 * 60 * 60 * 1000))
                const today = kstDate.toISOString().slice(0, 10)

                this.isCommentSurveyEnd = (this.commentSurvey.endDate.slice(0, 10) < today)

                // 응답값 초기화
                for (let i = 0; i < this.commentSurvey.commentSurveyElements.length; i += 1) {
                  const e = this.commentSurvey.commentSurveyElements[i]
                  e.answer = -1
                  this.userCommentSurveyAnswer[e.seq - 1] = 0

                  // 선택 상태 지정
                  for (let j = 0; j < e.commentSurveyElementChoices.length; j += 1) {
                    const c = e.commentSurveyElementChoices[j]
                    c.answerCount = 0
                  }
                }
              } else {
                this.commentSurvey = null
                this.commentSurveyId = -1
                this.isCommentSurveyEnd = true
                this.isAnswerSummitComplete = false
              }

              // 현재 사용자의 기존 투표 의견 가져오기 (있을 경우만)
              if (this.commentlist.length > 0) {
                // 현재 사용자 댓글 찾기
                const oldAnswer = this.commentlist
                  .flatMap(
                    comment => [comment, ...(comment.childrenComments || [])] // comment와 childrenComments를 하나의 배열로 합침
                  )
                  .find(x => x.userHash === this.shaEnLicenseNum && x.commentSurveyAnswer !== null)
                  ?.commentSurveyAnswer

                if (!oldAnswer) {
                  this.userCommentSurveyAnswer = Array(this.commentSurvey.commentSurveyElements.length).fill(0)
                } else {
                  this.userCommentSurveyAnswer = this.stringToArray(oldAnswer)
                  this.isAnswerSummitComplete = true

                  // 의견이 있었던 경우 e.answer Update
                  for (let i = 0; i < this.userCommentSurveyAnswer.length; i += 1) {
                    const answer = this.userCommentSurveyAnswer[i]
                    const element = this.commentSurvey.commentSurveyElements.find(x => x.seq === i + 1)
                    if (element !== undefined) {
                      const choice = element.commentSurveyElementChoices.find(x => x.id === answer)
                      if (choice !== undefined) {
                        element.answer = answer
                      }
                    }
                  }
                }
              }

              // choice status set
              for (let i = 0; i < this.commentSurvey.commentSurveyElements.length; i += 1) {
                const e = this.commentSurvey.commentSurveyElements[i]
                // 선택 상태 지정
                for (let j = 0; j < e.commentSurveyElementChoices.length; j += 1) {
                  const c = e.commentSurveyElementChoices[j]
                  c.status = this.setItemClass(e, c)
                }
              }

              // 투표 후라면 전체 결과 계산
              if (this.isAnswerSummitComplete || this.isCommentSurveyEnd) {
                // 사용자 당 하나의 응답 추출
                const totalCommentSurveyAnswer = this.commentlist
                  .flatMap(
                    comment => [comment, ...(comment.childrenComments || [])] // comment와 childrenComments를 하나의 배열로 합침
                  )
                  .filter(x => x.commentSurveyAnswer !== null)
                  .reduce((acc, current) => {
                    const x = acc.find(item => item.userHash === current.userHash)
                    if (!x) {
                      acc.push(current)
                    }
                    return acc
                  }, [])

                this.totalCommentSurveyAnswerCount = totalCommentSurveyAnswer.length

                // 집계
                for (let i = 0; i < this.commentSurvey.commentSurveyElements.length; i += 1) {
                  const e = this.commentSurvey.commentSurveyElements[i]

                  for (let j = 0; j < e.commentSurveyElementChoices.length; j += 1) {
                    const c = e.commentSurveyElementChoices[j]

                    const count = totalCommentSurveyAnswer.filter(x => x.commentSurveyAnswer.includes(c.id)).length
                    c.answerCount = count
                  }
                }
              }
            })
            .catch(() => {})
        })
        .catch(() => {})
    },

    async checkedLogin() {
      let isLoggedIn = false

      if (this.memberOnly || (this.memberOnly === false && this.join)) {
        // 회원만 댓글 허용(default) 또는 비회원 허용이면서 회원인 경우에는 로그인 필수
        isLoggedIn = await isUserLoggedIn()
      } else {
        // 비회원 허용이면서 비회원인 경우
        isLoggedIn = true
      }

      if (!isLoggedIn) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: this.$route.name },
        })
      }
    },
    modifyComment(item) {
      this.$set(item, 'modifyComment', !item.modifyComment)
    },
    showReply(item) {
      this.checkedLogin()
      this.$set(item, 'replyWrite', !item.replyWrite)
    },
    cancelReply(item) {
      this.$set(item, 'replyWrite', false)
      this.$set(item, 'replyEditText', '')
    },
    modifyReply(item) {
      this.$set(item, 'modifyReply', !item.modifyReply)
    },
    register() {
      if (this.isProcessing) return

      this.checkedLogin()

      if (this.commentSurvey && !this.isCommentSurveyEnd && !this.isAnswerSummitComplete) {
        // 설정된 투표가 있고 + 진행중인 경우 + 사용자가 투표 전이라면

        // 모든 문항에 응답했는지 확인
        if (this.userCommentSurveyAnswer.filter(x => x === 0).length > 0) {
          this.showAlertDialog('의견 선택 후 댓글 입력이 가능합니다.')
          return
        }
      }

      if (this.commentText == null || this.commentText.length === 0) {
        this.showAlertDialog('댓글을 입력해주세요.')
        return
      }

      if (this.isHook) {
        // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
        this.$emit('hook', { call: 'createComment' })
        return
      }

      // 댓글 저장
      this.createComment()
    },
    createComment() {
      this.isProcessing = true

      axios.post(`/fu/comment`, {
        contentId: this.contentId,
        contentType: this.contentType,
        commentText: this.commentText,
        userName: this.userName,
        medicalDept: this.medicalDept,
        userHash: this.shaEnLicenseNum,
        commentSurveyAnswer: this.userCommentSurveyAnswer.length > 0 ? this.userCommentSurveyAnswer.toString() : null, // 의견이 있는 경우만 반환
      })
        .then(rs => {
          this.commentText = null
          this.isProcessing = false

          this.fetchComment(this.contentId, this.contentType)

          if (this.contentType === 2) {
            confirmPointMission(this.contentType === 1 ? 12 : 13)
            setStatistics(this.contentType === 1 ? 126 : 146, this.contentId, rs.data)
          }
        })
        .catch(() => {
          this.isProcessing = false
          this.showAlertDialog('댓글 작성 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    registerReply(item) {
      if (this.isProcessing) {
        return
      }

      if (this.commentSurvey && !this.isCommentSurveyEnd && !this.isAnswerSummitComplete) {
        // 설정된 투표가 있고 + 진행중인 경우 + 사용자가 투표 전이라면

        // 모든 문항에 응답했는지 확인
        if (this.userCommentSurveyAnswer.filter(x => x === 0).length > 0) {
          this.showAlertDialog('의견 선택 후 댓글 입력이 가능합니다.')
          return
        }
      }

      if (item.replyComment == null || item.replyComment.length === 0) {
        this.showAlertDialog('답글을 입력해주세요.')
        return
      }

      if (this.isHook) {
        // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
        this.$emit('hook', {
          call: 'createReply',
          parentCommentId: item.commentId,
          replyComment: item.replyComment
        })
        return
      }

      // 답글 저장
      this.createReply(item.commentId, item.replyComment)
    },
    createReply(parentCommentId, replyComment) {
      this.isProcessing = true

      axios.post(`/fu/comment`, {
        contentId: this.contentId,
        contentType: this.contentType,
        commentText: replyComment,
        parentId: parentCommentId,
        userName: this.userName,
        medicalDept: this.medicalDept,
        userHash: this.shaEnLicenseNum,
        commentSurveyAnswer: this.userCommentSurveyAnswer.length > 0 ? this.userCommentSurveyAnswer.toString() : null,
      })
        .then(() => {
          this.commentText = null
          this.isProcessing = false

          this.fetchComment(this.contentId, this.contentType)

          if (this.contentType === 1 || this.contentType === 2) confirmPointMission(this.contentType === 1 ? 12 : 13)
        })
        .catch(() => {
          this.isProcessing = false
          this.showAlertDialog('답글 작성 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    updateComment(item) {
      if (item.commentText === null || item.commentText.length === 0) {
        this.showAlertDialog('댓글을 입력해주세요.')
        return
      }
      axios.put(`/fu/comment`, {
        commentId: item.commentId,
        commentText: item.commentText
      })
        .then(() => {
          this.fetchComment(this.contentId, this.contentType)
        })
        .catch(() => {
          this.showAlertDialog('댓글 수정 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    removeComment(item) {
      this.showConfirmDialog('해당 댓글을 삭제하시겠습니까?', (result) => {
        if (result) {
          if (this.isHook) {
            // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
            this.$emit('hook', {
              call: 'removeComment',
              commentId: item.commentId
            })
            return
          }

          // 댓글 삭제
          this.deleteComment(item.commentId)
        }
      })
    },
    deleteComment(commentId) {
      axios.delete(`/fu/comment/delete/${commentId}`)
        .then(() => {
          this.fetchComment(this.contentId, this.contentType)
        })
        .catch(() => {
          this.showAlertDialog('댓글 삭제 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    commentSurveyAnswerSelect(e, c) {
      // 로그인 여부 확인 (비회원은 투표 불가)
      this.checkedLogin()

      // 이미 의견을 선택한 경우 return
      if (this.isAnswerPickComplete) {
        if (e.answer !== -1) {
          // 응답한 문항에 답했으면 return
          return
        }
      }
      this.showConfirmDialog('의견 선택 후에는 변경할 수 없으며,\n댓글을 작성해야 참여가 완료됩니다.\n해당 의견을 선택하시겠습니까?', (result) => {
        if (result) {
          this.isAnswerPickComplete = true

          e.answer = c.id
          this.userCommentSurveyAnswer[e.seq - 1] = c.id

          this.choiceStatueUpdate(e)
        }
      })
    },
    setItemClass(e, c) {
      let itemClass = 'rs-item '
      if (this.isCommentSurveyEnd || this.isAnswerSummitComplete || this.isAnswerPickComplete) {
        /// /// 설문 기간 만료 or 설문 참여 완료 or 설문 선택 완료
        // itemClass += 'disabled ' // 회색조
        itemClass += 'result ' // 결과 표시
        if (e.answer === c.id) {
          /// 선택 항목(참여 완료시)
          itemClass += 'active ' // 강조 표시
        } else {
          /// 미선택 항목
          itemClass += 'else '
        }
      } else if (e.answer === c.id) {
        /// 선택 항목(참여 완료시)
        itemClass += 'active ' // 강조 표시
      } else {
        /// 미선택 항목
        itemClass += 'else '
      }

      return itemClass
    },
    getAnswerInfo(answerId, category) {
      // 로드된 설문 중 답변 찾기
      for (let i = 0; i < this.commentSurvey.commentSurveyElements.length; i += 1) {
        const element = this.commentSurvey.commentSurveyElements[i]
        const choice = element.commentSurveyElementChoices.find(c => c.id === answerId)
        if (choice) {
          if (category === 'color') {
            return choice.colorCode
          }
          if (category === 'seq') {
            return choice.seq
          }
        }
      }
      // 해당되는 문항이 없는 경우 (응답 후 설정이 변경된 경우)
      if (category === 'color') {
        return '#888'
      }
      if (category === 'seq') {
        return '0'
      }
      return null
    },
    stringToArray(intString) {
      return intString.split(',').map(num => parseInt(num.trim(), 10))
    },
    choiceStatueUpdate(e) {
      for (let j = 0; j < e.commentSurveyElementChoices.length; j += 1) {
        e.commentSurveyElementChoices[j].status = this.setItemClass(e, e.commentSurveyElementChoices[j])
      }

      this.commentSurvey.commentSurveyElements = [...this.commentSurvey.commentSurveyElements]
    },
  },
}
</script>
<style scoped>
.reply-survey-type .reply-survey-wrap .reply-survey-items .rs-item.result:hover{
  cursor: default;
}
.reply-survey-type .reply-survey-wrap .reply-survey-items .rs-item.result:hover:not(.active){
  outline: 1px solid rgba(63, 116, 244, 0.56);
}
.reply-survey-type .reply-survey-wrap .reply-survey-items .rs-item.result:hover.active{
  outline: 2px solid #3F74F4;
}
.reply-survey-type .reply-survey-wrap .reply-survey-items .rs-item.result:hover:not(.active) .title{
  color: #555;
  font-weight: 500;
}
.reply-survey-type .reply-survey-wrap .reply-survey-items .rs-item.result.active .title{
  color: #3F74F4;
  font-weight: 700;
}
.rs-item.result .title {
  padding-right: 10px;
}
</style>
